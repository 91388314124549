import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography, Grid, InputLabel, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { recoverPassword, removeCookie } from '../../../store/services/Login';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import DialogMessage from '../../common/DialogMessage';
import RawMarkup from '../../common/RawMarkup';
import { useClientData } from '../../../context/ClientContext';

const FIELDS = ['user'];

const RecoverPasswordForm = () => {
  const { t } = useTranslation();
  const { clientData, setClientData } = useClientData();
  const navigate = useNavigate();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDialogRecover, setOpenDialogRecover] = useState(false);
  const [openDialogMessage, setOpenDialogMessage] = useState(false);

  useEffect(() => {
    const jwt = localStorage.getItem('jwt');
    if (jwt) {
      setClientData(null);
      localStorage.clear();
    }
    removeCookie();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenDialogRecover = () => {
    setOpenDialogRecover(true);
  };

  const handleCloseDialogRecover = () => {
    setOpenDialogRecover(false);
  };

  const handleOpenDialogMessage = () => {
    setOpenDialogMessage(true);
  };

  const handleCloseDialogMessage = () => {
    setOpenDialogMessage(false);
    navigate('/login');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errorsAux = {};

    FIELDS.forEach(elem => {
      if (!form[elem]) {
        errorsAux[elem] = true;
      }
    })

    setErrors(errorsAux);

    if (Object.keys(errorsAux).length === 0) {
      handleOpenDialogMessage();
      setLoading(true);
      recoverPassword(form['user']).then(res => {
        setLoading(false);
        handleOpenDialogMessage();
      }).catch(error => {
        setShowError(error?.message || t('common.anErrorOcurred'));
        setLoading(false);
      });
    }
  };

  const modalMessage = `<p style='font-size:18px;'>${t('common.registerMessage')} <strong style='color:#002848;'>trens@ttoperadora.com.br</strong><p>`;

  const handleClickRegisterLink = () => {
    const registerLink = clientData?.client?.register?.link;
    if (registerLink) {
      window.location.href = registerLink;
    } else {
      handleOpenDialogRecover(); // Abre el diálogo si no hay link
    }
  };

  return (
    <>
      <Grid className='form'>
        <Typography className='title'>{t('common.recoverPassword')}</Typography>
        <form onSubmit={handleSubmit} noValidate>
          {showError && (
            <Alert severity='error' style={{ marginBottom: 10 }}>{showError}</Alert>
          )}
          <InputLabel>{t('common.user')}</InputLabel>
          <TextField
            fullWidth
            id="user"
            name="user"
            autoComplete="user"
            placeholder={t('common.user')}
            size='small'
            value={form.user || ''}
            error={errors.user}
            disabled={loading}
            onChange={(e) => setForm(current => ({ ...current, user: e.target.value }))}
          />

          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            className='submit-button'
            loading={loading}
          >
            <span>{t('common.recover')}</span>
          </LoadingButton>
        </form>

        <Grid className='buttons-bottom row'>
          <Button variant='standard' onClick={() => navigate('/login')}>{t('common.logInTitle')}</Button>
          <Button variant='standard' onClick={handleClickRegisterLink}>{t('common.signIn')}</Button>
        </Grid>
      </Grid>

      <DialogMessage
        open={openDialogRecover}
        onClose={handleCloseDialogRecover}
        title={t('common.registerTitle')}
        message={<RawMarkup content={modalMessage} />}
        showCloseButton
      />

      <DialogMessage
        open={openDialogMessage}
        onClose={handleCloseDialogMessage}
        title={t('common.recoverPassword')}
        message={<Typography style={{ fontSize: 18 }}>{t('common.emailRecoverPassword')}</Typography>}
        showCloseButton
      />
    </>
  );
}

export default RecoverPasswordForm;
