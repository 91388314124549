import axios from 'axios';
import dayjs from 'dayjs';
import { getCredentials } from '../sagas/util';

// const CLIENT_URL = 'https://zo6u53upmg.execute-api.us-east-2.amazonaws.com/dev/'; // STG
const CLIENT_URL = 'https://8psxdf9bc3.execute-api.us-east-2.amazonaws.com/dev-gtr/'; // PROD

const getClient = async (data) => {
  const headers = {
    'Content-Type': 'application/json'
  };

  try {
    const response = await axios.post(`${CLIENT_URL}clientconfig`, data, { headers });
    return response.data;
  } catch (error) {
    throw new Error(error)
  }
}

const autologin = async (data) => {
  const headers = {
    'Content-Type': 'application/json'
  };

  try {
    const response = await axios.post(`${CLIENT_URL}authorization`, data, { headers });
    return response.data;
  } catch (error) {
    throw new Error('El usuario y/o contraseña es incorrecto.')
  }
}

const login = async (fingerprint, data) => {
  const headers = {
    'Content-Type': 'application/json',
    'visitor-id': fingerprint
  };

  try {
    const response = await axios.post(`${getCredentials().urlB2Login}user/login`, data, { headers });
    setCookies(response.data);
    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
}

const register = async (data, token) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': token
  };

  try {
    const response = await axios.post(`https://admin-productos.tije.travel/api/create-client/`, data, { headers });
    return response.data;
  } catch (error) {
    return false;
  }
}

const recoverPassword = async (username) => {
  const headers = {
    'Content-Type': 'application/json'
  };

  try {
    const response = await axios.get(`${getCredentials().urlB2Login}user/email/${username}`, { headers });
    return response.data;
  } catch (error) {
    throw new Error('Ha ocurrido un error.')
  }
}

const getProducts = async (access) => {
  const headers = {
    'Content-Type': 'application/json',
    'authentication-token': access
  };

  try {
    const response = await axios.get(`${getCredentials().urlB2}user/product/`, { headers });
    return response.data;
  } catch (error) {
    throw new Error('Ha ocurrido un error inesperado.')
  }
}

const getCurrencies = async (access, productToken) => {
  const headers = {
    'Content-Type': 'application/json',
    'authentication-token': access
  };

  try {
    const response = await axios.get(`${getCredentials().urlB2}product-by-work-unit/currency/?token_product=${productToken}&format_=1`, { headers });
    return response.data;
  } catch (error) {
    return {};
  }
}

const getBanners = async (access) => {
  const headers = {
    'Content-Type': 'application/json',
    'authentication-token': access
  };

  try {
    const response = await axios.get(`${getCredentials().urlB2}banner/get/formatted-banner`, { headers });
    return response.data;
  } catch (error) {
    return {};
  }
}

const getLandings = async (access) => {
  const headers = {
    'Content-Type': 'application/json',
    'authentication-token': access
  };

  try {
    const response = await axios.get(`${getCredentials().urlB2}landing/get/by-user`, { headers });
    return response.data;
  } catch (error) {
    return [];
  }
}

const getLandingByURL = async (access, url) => {
  const headers = {
    'Content-Type': 'application/json',
    'authentication-token': access
  };

  try {
    const response = await axios.get(`${getCredentials().urlB2}landing/get/by-url/${url}`, { headers });
    return response.data;
  } catch (error) {
    throw new Error('Ha ocurrido un error inesperado.')
  }
}

const getLandingByURLNoLogin = async (unit_url, landing_url) => {
  const headers = {
    'Content-Type': 'application/json'
  };

  try {
    const response = await axios.get(`${getCredentials().urlB2}landing/get/by-url-no-login/`, {
      headers,
      params: { unit_url, landing_url }
    });
    return response.data;
  } catch (error) {
    throw new Error('Ha ocurrido un error inesperado.')
  }
}

const getPayment = async (access) => {
  const headers = {
    'Content-Type': 'application/json',
    'authentication-token': access
  };

  try {
    const response = await axios.get(`${getCredentials().urlB2}business-unit/payment/`, { headers });
    return response.data;
  } catch (error) {
    return null;
  }
}

const checkToken = async (access) => {
  const headers = {
    'Content-Type': 'application/json',
    'authentication-token': access
  };

  try {
    const response = await axios.get(`${getCredentials().urlB2Login}check-token`, { headers });
    return response.data;
  } catch (error) {
    throw new Error('Ha ocurrido un error inesperado.')
  }
}

const subscribesNewsletter = async (token, email, origin) => {
  const headers = {
    'token': token
  };

  try {
    const response = await axios.get(getCredentials().urlNewsletter, {
      headers,
      params: { email, origin }
    });
    return response.data;
  } catch (error) {
    throw new Error('Ha ocurrido un error inesperado.')
  }
}

const setCookies = data => {
  const cookie = {
    hashuser: data.user.hashuser,
    first_name: data.user.name,
    username: data.user.email,
    jwt: data.jwt,
    client_id: data.client_id,
    application_id: data.application_id
  };
  const dinamicDomain = window.location.hostname;
  const expirationDate = dayjs().add(8, 'hours').toString()
  const expires = "expires=" + expirationDate;
  document.cookie =
    "session=" + JSON.stringify(cookie) +
    "; " + expires +
    "; domain=" + dinamicDomain +
    "; path=/; Secure; SameSite=Lax";
}

const removeCookie = () => {
  const dinamicDomain = window.location.hostname;
  document.cookie = "session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" + dinamicDomain + "; Secure; SameSite=Lax";
};

export {
  getClient,
  autologin,
  login,
  register,
  recoverPassword,
  getProducts,
  getCurrencies,
  getBanners,
  getLandings,
  getLandingByURL,
  getLandingByURLNoLogin,
  getPayment,
  checkToken,
  removeCookie,
  subscribesNewsletter
}
