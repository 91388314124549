const pt = {
  translation: {
    common: {
      noOptions: 'Sem opções',
      loading: 'Carregando',
      continue: 'Continuar',
      back: 'Voltar',
      cancel: 'Cancelar',
      modifyAndAdd: 'Modificar e adicionar',
      modify: 'Modificar',
      reserve: 'Reserve',
      search: 'Pesquisar',
      searchingBestPrices: 'Estamos procurando os melhores preços',
      processingRequest: 'Estamos processando a solicitação',
      processingBooking: 'Estamos processando a reserva',
      hello: 'Olá',
      myBookings: 'Minhas reservas',
      seeMoreBookings: 'Ver mais reservas',
      logIn: 'Entrar',
      logOut: 'Fechar sessão',
      favorites: 'Favoritos',
      logInTitle: 'Iniciar Sessão',
      logInCodeTitle: 'Precisamos validar seu nome de usuário',
      logInCodeSubtitle: 'Digite o código que você recebeu em seu e-mail para continuar',
      user: 'Usuário',
      password: 'Senha',
      logInButton: 'Iniciar sessão',
      recoverPassword: 'Recuperar senha',
      enterWithOtherUser: 'Faça login com outro usuário',
      signIn: 'Registro',
      suscribe:'Subscreva e receba as nossas ofertas',
      successSuscribes: 'Assinatura concluída com sucesso',
      enterEmail: 'Inserir um e-mail',
      showMore: 'Ver mais',
      send: 'Enviar',
      recover: 'Recuperar',
      registerTitle: 'Registro de Usuário',
      registerMessage: 'Solicite sua inscrição escrevendo para',
      emailRecoverPassword: 'Um e-mail foi enviado para você para recuperar sua senha.',
      anErrorOcurred: 'Ocorreu um erro.',
      close: 'Fechar',
      guard: 'Guarda',
      sales: 'Vendas',
      reservation: 'Reserva',
      open: 'Aberta',
      cancelled : 'Cancelada',
      closed : 'Fechada',
      products: 'Produtos',
      passenger: 'Passageiro',
      date: 'Data',
      inProgress: 'No curso',
      continueBooking: 'Continuar reserva',
      exchangeTypeTtle: 'Taxa de câmbio',
      exchangeTypeDescription: 'A taxa de câmbio é definida às 11:00, antes deste horário o pagamento estará sujeito a ajustes.',
      availableCredit: 'Crédito disponível',
      expiredSession: 'Sua sessão expirou, faça login novamente.',
      consult: 'Consultar',
      modules: {
        hotels: 'hotéis',
        trains: 'trens',
        flights: 'voos'
      },
      important: 'Importante',
      verificationCode: 'Código de verificação',
      enterCode: 'Insira o código',
      loginErrorMessage: 'O nome de usuário e/ou senha estão incorretos.',
      deviceCodeExpiredTitle: 'O código expirou. Por favor, verifique seu e-mail.',
      deviceCodeInvalidTitle: 'O código não é válido. Por favor, insira-o novamente.',
      resetPasswordTitle: 'O número de tentativas permitidas foi excedido.',
      resetPasswordSubtitle: 'Verifique seu e-mail para redefinir sua senha.',
      userDisabledTitle: 'O número de tentativas permitidas foi excedido.',
      userDisabledSubtitle: 'Redefina sua senha e tente novamente.'
    },
    register: {
      title: 'Registro',
      lastNameFirstNameUser: 'Sobrenome e nome do requerente',
      lastNameFirstName: 'Sobrenome e nome',
      agencyName: 'Nome da agência',
      businessName: 'Razão social',
      phoneNumber: 'Número de telefone',
      country: 'País',
      selectCountry: 'Selecione um pais',
      city: 'Cidade',
      selectCity: 'Selecione uma cidade',
      taxSituation: 'Situação fiscal',
      taxId: 'Identificação fiscal',
      enablingFileNumber: 'Número do arquivo qualificado',
      onlyArgentines: 'Somente para agências argentinas',
      email: 'Email',
      address: 'Endereço',
      create: 'Criar',
      enterValue: 'Insira um valor',
      invalidForm: 'O formulário não é válido, corrija quaisquer erros',
      onlyLetters: 'Insira apenas letras',
      created: 'Criada',
      requestSent: 'Sua solicitação foi enviada com sucesso',
      goLogin: 'Ir para login'
    },
    multiSearch: {
      hotels: {
        destination: 'Destino',
        checkIn: 'Check-in',
        checkOut: 'Check-out',
        nights: 'Noites',
        guests: 'Convidados',
        searchByInterestPoint: 'Pesquisar por ponto de interesse',
        adults: 'Adultos',
        minors: 'Crianças',
        roomsShort: 'Quartos',
        rooms: 'Quartos',
        room: 'Quarto',
        ages: 'Idades',
        agesTooltip: 'Idade dos menores na data de chegada',
        apply: 'Aplicar',
        selectDestination: 'Inserir destino',
        cities: 'Cidades',
        nationality: 'Nacionalidade'
      },
      trains: {
        tickets: 'Bilhetes',
        passes: 'Passes',
        direct: 'Direto',
        roundTrip: 'Ida e volta',
        origin: 'Origem',
        selectCity: 'Insira uma cidade',
        destination: 'Destino',
        selectDestination: 'Insira um destino',
        departure: 'Saída',
        return: 'Retorno',
        hour: 'Horário',
        firstDayOfValidity: 'Primeiro dia de validade',
        passengers: 'Passageiros',
        adult: 'Adulto',
        adults: 'Adultos',
        young: 'Jovem',
        youngs: 'Jovens',
        minorYoung: 'Criança/Jovem',
        seniors: 'Idosos',
        senior: 'Idoso',
        addSegment: 'Adicionar trechos',
        fromYears: 'A partir de {{years}} anos',
        toYears: 'Até {{years}} anos',
        fromToYears: '{{from}} - {{to}} anos',
        maxPassengers: 'Máx. {{passengers}} passageiros',
        atLeastOnePassenger: 'Você deve selecionar pelo menos um passageiro.',
        youngAge: 'Idade Jovem',
        years: 'Anos',
        SwitzerlandMinors: 'Crianças menores de 15 anos inclusive, viajando com os pais ou responsável legal, podem viajar gratuitamente com o Swiss Family Card.',
        city: 'Cidade',
        station: 'Estação'
      },
      flights: {
        roundtrip: 'Ida e volta',
        oneWay: 'Somente ida',
        multidestination: 'Multi-destinos',
        threeDays: 'Tarifas +/- 3 dias',
        selectOrigin: 'Insira a origem',
        selectDestination: 'Insira o destino',
        cities: 'Cidades',
        airports: 'Aeroportos',
        origin: 'Origem',
        destination: 'Destino',
        departure: 'Saída',
        return: 'Retornar',
        passengers: 'Passageiros',
        adults: 'Adultos',
        adult: 'Adulto',
        fromYears: '+ {{from}} anos',
        kids: 'Crianças',
        kid: 'Menino',
        fromToYears: '{{from}} a {{to}} anos',
        babys: 'Bebês',
        baby: 'Bebê',
        fromToMonths: '{{from}} a {{to}} meses',
        minorYears: 'Menos de {{years}} años',
        minorAges: 'As idades dos menores deverão ser as vigentes no momento do final da viagem.',
        addSegment: 'Adicionar seção',
        currency: 'Moeda',
        apply: 'Aplicar',
        advancedSearch: 'Busca avançada',
        airline: 'Companhia aérea',
        selectAirline: 'Insira uma companhia aérea',
        scaleType: 'Escalas',
        luggage: 'Bagagem',
        class: 'Classe',
        all: 'Todas',
        economy: 'Economy',
        premium: 'Premium',
        business: 'Business',
        firstClass: 'Primeira classe',
        direct: 'Direto',
        oneScale: '1 escala',
        twoScales: '2 escalas',
        threeScales: '3 ou mais',
        included: 'Incluindo',
        notIncluded: 'Não incluido',
        section: 'Seção'
      }
    },
    results: {
      hotels: {
        noHotels: 'Não há hotéis disponíveis em {{destination}}',
        otherSearch: 'Você pode realizar outra pesquisa',
        communicateAdvisor: 'Para agilizar o processamento de sua consulta, entre em contato com seu consultor de vendas.',
        availableHotels: '{{hotels}} hotéis disponíveis em {{destination}}',
        fromPrice: 'a partir de USD {{price}}',
        backResults: 'Voltar aos resultados',
        compareHotelsInDestination: 'Comparar hotéis em {{destination}}',
        addedNewFavorite: 'Novo favorito adicionado',
        sortBy: 'Classificar por',
        recommended: 'Recomendado',
        price: 'Preço',
        stars: 'Estrelas',
        distance: 'Distância',
        seeHotelsInMap: 'Ver hotéis no mapa',
        hotelRecommended: 'Acomodação recomendada!',
        seeHotelDetails: 'Ver detalhes do hotel',
        seeHotelInMap: 'Ver no mapa',
        withCancellationFees: 'Com taxas de cancelamento',
        noCancellationFees: 'Sem taxas de cancelamento',
        totalPriceFrom: 'Estadia total a partir de',
        pricePerNight: 'em média por noite',
        moreRates: 'Mais tarifas',
        compare: 'Comparar',
        onlyRoom: 'Somente quarto',
        breakfast: 'Café da manhã',
        allInclusive: 'Tudo incluso',
        halfPension: 'Meia pensão',
        distanceFromReference: '{{distance}} de {{destination}}',
        seeMap: 'Ver mapa',
        seeRates: 'Ver tarifas',
        amenities: 'Comodidades',
        ratesAndRoomType: 'Tarifas e tipo de quarto',
        noRooms: 'Não há quartos disponíveis para esse hotel.',
        apartmentInfo: 'IMPORTANTE: Esse tipo de acomodação exige que você entre em contato com o estabelecimento 72 horas antes do check-in para coordenar a recepção da acomodação.',
        bedType: 'Tipo de cama',
        room: 'quarto',
        refundable: 'Reembolsável',
        nonRefundable: 'Não Reembolsável',
        taxesAndFeesIncluded: 'Impostos e taxas incluídos',
        seeMoreOptions: 'Ver mais opções',
        seeHotelComments: 'Ver comentários do hotel',
        hotelComments: 'Comentários do hotel',
        freeCancellationBefore: 'Cancelamento grátis antes de {{date}}.',
        cancellationAfter: 'O cancelamento após o {{date}} será cobrado em {{currency}} {{price}}.',
        userWithourPermission: 'USUÁRIO SEM PERMISSÃO PARA RESERVAR COM TAXAS. Entre em contato com seu consultor de vendas para fazer uma reserva.',
        noAllowChangesOrCancellation: 'Não são permitidas alterações ou cancelamentos.',
        notAvailable: 'Não disponível',
        favoriteHotels: 'Hotéis favoritos',
        selectDates: 'Escolha datas',
        download: 'Baixar',
        print: 'Imprimir',
        modifyCriteria: 'Modificar critérios',
        addRecommendedHotels: 'Adicionar hotéis recomendados',
        addHotel: 'Adicionar hotel',
        addHotelFromResults: 'Adicionar hotéis a partir dos resultados',
        compareHotels: 'Comparar hotéis',
        clearSelection: 'Limpar seleção',
        amenitiesList: {
          wifi: 'WI-FI',
          parking: 'Estacionamento',
          pool: 'Piscina',
          pets: 'Animais de estimação permitidos',
          AC: 'Ar-condicionado',
          transfer: 'Serviço de transporte',
          gym: 'Academia',
          spa: 'Spa',
          beach: 'Perto da praia',
          wheelchair: 'Acesso para cadeira de rodas',
          kitchen: 'Cozinha',
          '24Hours': 'Recepção 24 horas',
          laundry: 'Serviço de lavanderia',
          playroom: 'Salão de jogos',
          nonSmokerRoom: 'Quartos para não fumantes',
          map: 'Localização no mapa',
          distances: 'Distâncias (centro, aeroporto, principal)'
        },
        selectAmenitites: 'Selecione seus critérios de comparação',
        selectAll: 'Selecionar tudo',
        defaultAmenities: 'Restaurar critérios padrão',
        updateComparation: 'Atualizar comparações',
        hotelNotFound: 'Hotel não encontrado',
        consultCancelationPolicy: 'Consulte política de cancelamento',
        showAllRooms: 'Ver todos os quartos',
        defaultRemarks: 'Alguns impostos locais podem não estar incluídos na tarifa.'
      },
      trains: {
        noTrains: 'Nosso sistema de trens não retornou nenhum resultado para esta pesquisa',
        otherSearch: 'Por favor tente novamente alterando a pesquisa',
        segment: 'Trecho',
        selectSegment: 'Selecione a tarifa',
        filter: 'Filtro',
        directs: 'Diretos',
        earlier: 'Mais cedo',
        schedules: 'Horários',
        later: 'Mais tarde',
        noDirectTrains: 'Nenhum trem direto foi encontrado para esta trecho',
        change: 'Conexão',
        changes: 'Conexões',
        train: 'Trem',
        bus: 'Bus',
        mostEconomical: 'Viagem mais económica!',
        recommendedTrip: 'Viagem recomendada! Melhor relação preço e duração',
        noTicketsSelected: 'Nenhum bilhete selecionado',
        selectTicketToContinue: 'Selecione sua tarifa para prosseguir',
        totalTickets: 'Total de bilhetes',
        notIncludeExpenses: 'Não inclui taxas',
        adults: 'Adultos',
        youngs: 'Jovens',
        seniors: 'Idosos',
        transferTime: 'Tempo para troca de trem',
        flexibilityAndConditions: 'Flexibilidade e condições tarifárias',
        seeConditions: 'Ver condições',
        totalSegment: 'Valor total',
        conditions: 'Condições de tarifa',
        totalByPassengers: 'Valor por número total de passageiros',
        tripSummary: 'Resumo da viagem',
        passe: 'Passe',
        duration: 'Duração',
        seeCompleteConditions: 'Veja condições completas',
        numberDays: 'Número de dias',
        totalPasses: 'Total de passes',
        noPasseSelected: 'Nenhum passe selecionado',
        selectPasseToContinue: 'Selecione uma tarifa para continuar',
        from: 'A partir de',
        to: 'Até',
        destination: 'Destino',
        type: 'Tipo',
        class: 'Clase',
        validFrom: 'Válido de',
        validTo: 'Válido até',
        termsAndConditions: 'Termos e condições',
        flexibleTypes: {
          bestPrice: 'Melhor preço',
          noFlexible: 'Não flexível',
          semiFlexible: 'Semi-flexível',
          fullFlexible: 'Flexível'
        },
        travelFreeMinorsTickets: '<b>Infoas:</b> Viajam gratuitamente <b>sem direito a lugar e sem bilhete</b> dependendo da idade: Comboios do Reino Unido 0-4 anos, Alemanha e Suíça 0-5 anos, e outros Comboios 0-3 anos inclusive.',
        travelFreeMinorsPasses: '<b>Infoas:</b> Viajam gratuitamente <b>sem direito a lugar e sem bilhete</b> dependendo da idade: Eurail Pass 0-3 anos e Swiss Pass 0-5 anos inclusive.',
        noResultsSegment: 'Nenhum resultado encontrado para esta trecho',
        errorAvailability: 'Erro de disponibilidade',
        noAvailabilityTrain: 'A disponibilidade para o próximo trem não foi confirmada.',
        noAvailabilityTrains: 'A disponibilidade para os seguintes trens não foi confirmada.',
        continueOthersTrains: 'Deseja continuar a reserva com o restante das seções selecionadas ou escolher uma nova alternativa?',
        modifySelectionTrain: 'Você quer escolher uma nova alternativa?',
        modifySelection: 'Modificar seleção',
        continue: 'Continuar',
        luggageConditions: {
          italoStandard: 'As tarifas não permitem bagagens maiores que 75x53x30 cm por passageiro.',
          brightlineStandard: 'As tarifas <b>Smart</b> permitem até 2 malas pequenas (71x56x35cm), com peso máximo de 22 kg cada, por passageiro.',
          brightlinePrimera: 'As tarifas <b>Premium</b> permitem 1 mala pequena (71x56x35cm.), de no máximo 22 kg. + 1 mala de até 178 cm lineares e 32 kg. por passageiro.'
        },
        foodConditions: {
          italoPrimera: 'As tarifas incluem lanches e bebidas a bordo.'
        },
        variableSeat: 'A inclusão de assentos é variável em cada trecho.',
        seatReservationIncluded: 'Reserva de assento incluída.',
        ticketMail:  'A companhia ferroviária enviará diretamente o bilhete eletrônico para o e-mail de contato informado para impressão e apresentação no embarque no trem.',
        noPrintTicket: 'Não é necessário imprimir o ticket. Para embarcar no trem deverá ser apresentado apenas um documento de identificação válido com foto e a referência PNR informada no e-mail de emissão.',
        downloadTicket: 'O bilhete eletrônico será enviado para download, impressão e apresentação no embarque no trem.',
        fareClasses: {
          standard: 'STANDARD',
          primera: 'COMFORT'
        },
        notAvailableSeats: 'Esta categoria não possui disponibilidade suficiente para o número de passageiros selecionados',
        baggageMessageFare: 'Tarifa com restrições de bagagem.',
        baggageFareTitle: 'Restrições de bagagem para {{ carriers }}',
        baggageFareMessageIryoInicial: 'As tarifas Inicial permitem apenas uma bagagem de mão (até 36x27x15cm.) e uma peça de bagagem de cabine (até 55x35x25cm.) por passageiro.',
        baggageFareMessageIryoSingular: 'As tarifas Singular permitem uma bagagem de mão (até 36x27x15cm.), uma bagagem de cabine (até 55x35x25cm.) e uma bagagem grande (até 70x55x35cm) por passageiro.',
        baggageFareMessageOuigoEssential: 'As tarifas Essential (Standard) permitem apenas uma bagagem de mão (até 27x36x15cm.) e uma bagagem de mão (até 55x35x25cm.) por passageiro.',
        baggageFareMessageItaloSmart: 'As tarifas Smart permitem apenas uma pequena bagagem (até 75x53x30cm) por passageiro. Em caso de excesso será aplicada multa e será solicitada descida na próxima parada. Esta limitação não se aplica às tarifas Comfort, Premium ou Club Executive. Radical Storage Platform: Serviço italiano de armazenamento de bagagem nas estações (aprox. Є5 por dia).'
      },
      flights: {
        noFlights: 'Não há voos disponíveis',
        otherSearch: 'Você pode fazer outra pesquisa',
        flightRecommended: 'Voo recomendado!',
        availableFlights: '{{flights}} voos disponíveis para {{destination}}',
        fromPrice: 'a partir de {{currency}} {{price}}',
        backResults: 'Voltar aos resultados',
        compareFlightsInDestination: 'Compare voos em {{destination}}',
        sortBy: 'Classificar por',
        recommended: 'Recomendado',
        price: 'Preço',
        duration: 'Duração',
        stopover: 'Escalas',
        departure: 'Ida',
        return: 'Volta',
        flight: 'Voo',
        economy: 'Economy',
        premium: 'Premium',
        business: 'Business',
        firstClass: 'Primeira classe',
        scales: 'Escalas',
        scale: 'Escala',
        direct: 'Direto',
        oneScale: '1 escala',
        twoScales: '2 escalas',
        threeScales: '3 ou mais escalas',
        scaleWait: 'Espera em {{city}} - {{hours}}h {{minutes}}m',
        scaleIn: 'Parada em {{airport}}',
        compare: 'Comparar',
        compareFlights: 'Comparar voos',
        clearSelection: 'Limpar seleção',
        publicFare: 'Taxa pública',
        privateFare: 'Taxa privada',
        priceByAdult: 'Preço por adulto',
        priceByKid: 'Preço por criança',
        priceByBaby: 'Preço por bebê',
        by: 'Por',
        taxes: 'Impostos',
        taxesAbb: 'Imp',
        adult: 'Adulto',
        kid: 'Criança',
        baby: 'Bebê',
        total: 'Total',
        dayAfter: 'Chega 1 depois',
        daysAfter: 'Chega {{ days }} depois',
        totalPrice: 'Preço total',
        seeDetail: 'Ver detalhe',
        class: 'Classe',
        flightNumber: 'Nº do voo',
        itinerary: 'Itinerário',
        flightTime: 'Tempo de voo',
        improveFlight: 'Melhore a experiência de voo',
        seeMoreRates: 'Veja mais tarifas',
        threeDaysPrices: 'Preços +/- 3 dias',
        threeDaysMessage: '(Os preços são para 1 passageiro de ida e volta, podem não estar atualizados no momento da cotação)',
        checkPrices: 'Verifique os preços',
        smallBaggageTitle: 'Inclui uma mochila ou bolsa',
        smallBaggageDescription: 'Deve caber embaixo do banco dianteiro.',
        mediumBaggageTitle: 'Inclui bagagem de mão',
        mediumBaggageDescription: 'Deve caber no compartimento superior do avião.',
        mediumBaggageNotIncluded: 'Não inclui bagagem de mão',
        largeBaggageNotIncluded: 'Não inclui bagagem para envio',
        largeBaggageIncluded: 'Inclui bagagem para enviar',
        pieceKgPerPassenger: '1 peça de {{ kg }} kg por {{ passenger }}.',
        piecePerPassenger: '1 peça por {{ passenger }}.',
        piecesPerPassenger: '{{ pieces }} peças para {{ passenger }}.',
        largeBaggageDescription: 'É despachado no aeroporto durante o Check-in.',
        seatSelection: 'Seleção de assento',
        changeable: 'Conexões',
        refundable: 'Devoluções',
        notIncluded: 'Não incluido',
        included: 'Incluindo',
        withExtraCost: 'Com custo',
        withoutExtraCost: 'Sem custo',
        notRefundable: 'Não reembolsável',
        notAllowed: 'Não permitido',
        allowedWithPenalty: 'Permitido com penalidade',
        allowedWithoutPenalty: 'Permitido sem penalidade',
        ratesByAirlines: 'Tarifas por companhia aérea',
        multipleAirlines: 'Mútiplas cias aéreas',
        airline: 'Companhia aérea',
        airports: 'Aeroportos',
        availableRate: 'Taxa disponível',
        estimatedRate: 'Taxa estimada. Verifique a validade e disponibilidade',
        checkPrice: 'Verificando a taxa de {{ currency }} {{ price }}',
        notAvailable: 'O voo selecionado não está disponível',
        notAlternativeFares: 'A companhia aérea não forneceu outras opções para a tarifa selecionada',
        refreshFare: 'Atualizar taxa',
        section: 'Seção',
        criteria: {
          duration: 'Duração',
          scales: 'Balanças',
          baggage: 'Bagagem',
          changeable: 'Mudanças',
          refundable: 'Devoluções',
          seatSelection: 'Seleção de assento'
        },
        modifyCriteria: 'Modificar critérios',
        download: 'Descarga',
        print: 'Imprimir',
        selectCriteria: 'Selecione seus critérios de comparação',
        selectAll: 'Selecionar tudo',
        updateComparation: 'Atualizar comparações'
      }
    },
    filters: {
      hotels: {
        filterSearch: 'Filtrar pesquisa',
        clear: 'Limpo',
        favorites: 'Favoritos',
        onlyFavorites: 'Somente favoritos',
        hotelName: 'Nome do hotel',
        selectHotel: 'Inserir hotéis',
        zones: 'Zonas',
        all: 'Todas',
        filterByStars: 'Classificação por estrelas',
        allStars: 'Todas as estrelas',
        filterByProperty: 'Tipo de acomodação',
        filterByBoardBase: 'Regime de refeições',
        filterByRateType: 'Tarifas',
        filterByAmenities: 'Comodidades',
        seeMoreAmenities: 'Ver mais comodidades',
        seeLessAmenities: 'Ver menos comodidades',
        filterByProviders: 'Fornecedores',
        seeMoreProviders: 'Ver mais fornecedores',
        seeLessProviders: 'Ver menos fornecedores',
        priceRange: 'Faixa de preço',
        refoundable: 'Reembolsável',
        nonRefoundable: 'Não Reembolsável',
        filterByRoomType: 'Tipo de quarto',
        filterByBedType: 'Tipo de cama',
        apply: 'Aplicar',
        properties: {
          apartment: 'Departamento',
          hostel: 'Hostel',
          hotel: 'Hotéis',
          other: 'Outro'
        }
      },
      flights: {
        filterSearch: 'Filtrar pesquisa',
        clear: 'Limpar',
        apply: 'Aplicar',
        filterByStopovers: 'Escalas',
        filterByBaggage: 'Bagagem',
        filterByAirline: 'Companhia aérea',
        filterByClass: 'Clase',
        filterByFareType: 'Tipo de taxa',
        filterByContentType: 'Tipo de conteúdo',
        priceRange: 'Faixa de preço',
        filterByAirport: 'Aeroporto',
        filterBySchedule: 'Horário',
        departureFrom: 'Decolagem de {{from}}',
        arrivalTo: 'Desembarque em {{to}}',
        section: 'Seção'
      }
    },
    checkout: {
      common: {
        agencyData: 'Dados da agência',
        nameInDocument: 'Conforme indicado no documento de viagem',
        name: 'Nome',
        enterName: 'Inserir um nome',
        surname: 'Último sobrenome',
        enterSurname: 'Inserir último sobrenome',
        phoneNumber: 'Número de telefone',
        enterPhoneNumber: 'Inserir um número de telefone',
        email: 'E-mail onde receberá a confirmação da reserva',
        enterEmail: 'Inserir um e-mail',
        observations: 'Observações (campo para uso interno, não dá origem a notificações ao fornecedor)',
        enterObservations: 'Introduza os seus comentários',
        passengersData: ' Dados dos passageiros ',
        passenger: 'Passageiro',
        adult: 'Adulto',
        minorYoung: 'Menor/Jovem',
        senior: 'Idoso',
        years: 'anos',
        names: 'Nome(s)',
        surnames: 'Último sobrenome',
        gender: 'Sexo',
        passportNumber: 'Número do passaporte',
        documentType: 'Tipo e número do documento',
        expirationDate: 'Data de expiração',
        birthday: 'Data de nascimento',
        nacionality: 'Nacionalidade',
        residence: 'Residência',
        titularPassenger: 'Passageiro titular',
        nameSurname: 'Nome e último sobrenome',
        nameSurnameTitular: 'Nome e último sobrenome do passageiro titular',
        titularPassengerTooltip: 'Estes dados devem corresponder ao passageiro titular da reserva, independentemente de ser ou não o pagador da reserva.',
        passengerTooltip: 'Esta informação deve corresponder ao pagador da reserva independentemente de ser ou não um dos viajantes.',
        enterCuilCuit: 'Inserir CUIL/CUIT',
        reservation: 'Reserva',
        reservationNumber: 'Número de reserva associado',
        reservationNumberTooltip: 'Preencha o campo para associar este produto a uma reserva existente.',
        optional: 'Opcional',
        firstName: 'Primeiro nome',
        lastName: 'Último sobrenome',
        totalToPay: 'Total a pagar',
        generalConditions: 'Li e aceito os Termos e Condições',
        general: 'Gerais',
        continue: 'Continuar',
        pay: 'Pagar',
        payPaypal: 'Continuar e pagar com PayPal',
        total: 'Total',
        totalTickets: 'Total de bilhetes',
        trainsAlertTooltip: 'Conexão de ônibus entre Saint-Jean de Maurienne e Oulx',
        comission: 'Comissão',
        subtotal: 'Subtotal',
        bookingFee: 'Taxa de Emissão',
        netRate: 'Taxa líquida',
        administrativeExpenses: 'Gastos Administrativos',
        netAgency: 'Valor Total NET',
        totalAmount: 'Valor Total',
        selectPassenger: 'Selecione o passageiro existente',
        accept: 'Aceitar',
        contact: 'Contato',
        passengers: 'Passageiro/s',
        emergencyEmail: 'E-mail - Emergências e alertas diretos',
        enterValidEmail: 'Entre com um e-mail válido',
        minLength: 'Deve ter no mínimo {{length}} caracteres',
        maxLength: 'Deve ter um comprimento máximo de {{length}} caracteres',
        minMaxNumber: 'Mínimo{{ minimun }}, máximo {{ maximun }} números',
        enterValidDate: 'Insira uma data válida',
        enterLaterDate: 'Insira uma data posterior',
        enterPreviousDate: 'Insira uma data anterior',
        enterLaterBirthdate: 'Ano de nascimento incorreto',
        enterPreviousBirthdate: 'Ano de nascimento incorreto',
        noSpecialCharacters: 'Insira apenas letras sem caracteres especiais, como ñ ou acentos',
        noRepeatDocumentNumber: 'O número do documento deve ser exclusivo',
        noRepeatName: 'Para passageiros com o mesmo nome e sobrenome, a primeira letra do nome do meio deve ser adicionada, ou JR para a criança',
        chosenPayment: 'Você escolheu pagar com {{ paymentMethod }}',
        numberOfCards: 'Com quantos cartões você deseja pagar?',
        oneCard: 'Um cartão de crédito',
        twoCards: 'Dois cartões de crédito',
        firstCard: 'Primeiro cartão de crédito',
        secondCard: 'Segundo cartão de crédito',
        payment: 'Pagar',
        internalCredit: 'Crédito interno',
        creditMessageSuccess: 'A reserva será emitida e o seu valor será deduzido do crédito disponível.',
        creditMessageError: 'O crédito disponível é insuficiente para avançar para a questão. Entre em contato conosco para continuar com o processo ou selecione outro método de pagamento.',
        modify: 'Modificar forma de pagamento',
        makePayment: 'Faça seu pagamento!',
        howToPay: 'Como você quer pagar?',
        addAssitance: 'Adicionar assistência de viagem',
        bookingNumber: 'Número da reserva',
        bookingStatus: 'Status da reserva',
        paymentStatus: 'Status do pagamento',
        payWith: 'Pagar com',
        taxesIncluded: 'Impostos e taxas incluídas',
        comments: 'Comentarios',
        attention: 'Atenção!',
        changePriceMessage: 'Houve uma atualização na tarifa no momento da confirmação.',
        changePriceReservation: 'Informamos que houve uma atualização da tarifa no momento do processamento da sua reserva.',
        creditCardButton: 'Cartão de débito ou crédito',
        completeFieldsPaypal: 'Preencha os dados para avançar com a emissão da reserva',
        completeAllFieldsPaypal: 'Por favor preencha o formulário de pagamento. (Todos os campos são obrigatórios)',
        purchaseDetailTitle: 'Detalhes da reserva',
        billingData: 'Dados cartão',
        cardNumber: 'Número de cartão',
        enterCardNumber: 'Inserir o número do cartão',
        holderName: 'Titular do cartão',
        enterHolderName: 'Insira o titular do cartão',
        expiry: 'Data de vencimento',
        enterExpiry: 'Insira a data de validade',
        cvc: 'Código de segurança',
        enterCVC: 'Inserir o código de segurança',
        documentNumber: 'Número do documento',
        enterDocumentNumber: 'Insira o número do documento',
        valid: 'Valido ate',
        enterValidCardNumber: 'Insira um número de cartão válido',
        enterValidDocumentNumber: 'Insira um número de documento válido',
        enterValidZipCode: 'Insira um CEP válido',
        kid: 'Criança',
        baby: 'Bebê',
        ageMessage: 'A idade corresponde aos anos completados no final da viagem',
        quotas: 'Parcelas',
        dni: 'DNI',
        passport: 'Passaporte',
        creditCard: 'Cartão de crédito',
        comissionIncluded: 'Comissão incluída',
        enterAmountToPay: 'Insira valor a pagar',
        amountToPay: 'Valor a pagar',
        personalInformationAndAddress: 'Informações pessoais e endereço',
        personalInformation: 'Informações pessoais',
        zipCode: 'CEP',
        addressStreet: 'Endereço',
        addressNumber: 'Número',
        complement: 'Complemento',
        district: 'Barrio',
        country: 'País',
        city: 'Cidade',
        state: 'Estado',
        autocomplete: 'Preenchimento automático',
        rejectedPayment: 'Pagameto recusado!',
        rejectedPaymentMessage: 'O pagamento não pode ser processado. Tente novamente.',
        minimunAmountCard: 'Valor mínimo de pagamento {{ currency }} {{ amount }}',
        qrCode: 'código QR',
        createQrCode: 'Criar Código QR',
        qrCodeMessage: 'O código QR expirará em {{ minutes }} minutos. Se o pagamento não for concluído, um novo código será gerado automaticamente. Por favor, não feche nem atualize a página durante o processo.',
        qrCodeMessageWithSeconds: 'O código QR expirará em {{ minutes }} minutos e {{ seconds }} segundos. Se o pagamento não for concluído, um novo código será gerado automaticamente. Por favor, não feche nem atualize a página durante o processo.',
        qrCodeMessageOnlySeconds: 'O código QR expirará em {{ seconds }} segundos. Se o pagamento não for concluído, um novo código será gerado automaticamente. Por favor, não feche nem atualize a página durante o processo.',
        errorGeneratingQr: 'Ocorreu um erro ao gerar um código QR. tente novamente',
        payWithQrCode: 'Pague com código QR',
        totalLiquidation: 'Total liq.',
        order: 'Pedido',
        paymentInstructions: 'Instruções de pagamento',
        pixInstructions: {
          one: 'Abra a app do seu banco',
          two: 'Selecione a opção de pagamento via Pix',
          three: 'Selecione a opção QR Code',
          four: 'Autorize o pagamento',
          five: '<b>Aguarde</b> até o banco confirmar o pagamento',
          six: '<b>Não feche ou minimize o navegador</b>',
          seven: '<b>Não</b> atualize a página (F5)',
          eight: '<b>Após o pagamento seu pedido será concluído automaticamente</b>'
        }
      },
      timer: {
        expiredSearch: 'A opção selecionada expirou!',
        returnResultsOf: 'Por favor, inicie uma nova pesquisa.',
        returnResults: 'Voltar aos resultados',
        reservationExpireIn: 'A reserva expirará em',
        timeToReservation: 'Você está a um passo de fazer sua reserva. Decorrido o tempo de preenchimento do formulário, será necessário realizar uma nova pesquisa.'
      },
      error: {
        title: 'Sentimos muito! Página não encontrada',
        description: 'A página que você procurava não foi encontrada, mas você pode voltar ao início e continuar procurando sua viagem ideal.',
        returnHome: 'Voltar ao início',
        returnResults: 'Voltar aos resultados',
        reservationNotExists: 'A reserva inserida não existe'
      },
      secureSite: {
        title: '{{ site }} é um lugar seguro',
        titleIframe: 'Lugar seguro',
        description: 'Usamos conexões seguras para proteger suas informações.'
      },
      thankYouPage: {
        sucessBooking: 'Sua reserva foi realizada com sucesso!',
        operationPerformed: 'Operação realizada',
        confirmed: 'Confirmado',
        autocancel: 'Caso o pagamento correspondente não seja informado, a reserva será autocancelada conforme indicado na Política de Cancelamento.',
        pendingIssue: 'Emissão pendente',
        approvedPayment: 'Pagamento aprovado',
        rejectedPayment: 'Pagamento recusado',
        rejectedPaymentMessage: 'Obrigado por nos escolher! A reserva não pôde ser emitida porque o pagamento foi rejeitado. Por favor, faça uma nova reserva e tente pagar novamente com um cartão diferente. Se você tiver alguma dúvida ou precisar de ajuda, entre em contato conosco.',
        sucessBookingPendingIssue: 'A reserva foi gerada com sucesso e está pendente de emissão. Só é garantido mediante pagamento. A tarifa final está sujeita à confirmação.',
        errorBooking: 'Não foi possível fazer sua reserva',
        errorBookingProccess: 'A reserva está em processo de confirmação.',
        sorry: 'Sentimos muito!',
        attention: '¡Atenção!',
        rejected: 'Rejeitado',
        unconfirmedReservation: 'O pedido de reserva não pôde ser confirmado. Por favor, tente novamente.',
        failedOperation: 'Operação falhada',
        rejectedBooking: 'Reserva rejeitada',
        continueBooking: 'Continuar reserva',
        anErrorOcurred: 'Ocorreu um erro. Entre em contato conosco para que possamos atendê-lo e fornecer mais detalhes sobre sua solicitação.',
        managingBooking: 'Estamos gerenciando sua reserva',
        pendingConfirmation: 'Pendente de confirmação',
        bookingInProcess: 'SUA RESERVA ESTÁ EM PROCESSO. NÃO FECHE ESTA JANELA.',
        bookingStatusOnConfirm: 'Aqui você verá o status da sua reserva depois de processada.',
        pendingPaymentTitle: 'Reserva pendente de pagamento',
        pendingOperation: 'Operação pendente',
        requestedIssue: 'Emissão solicitada',
        approvedCredit: 'Aprovado com crédito',
        sucessBookingAndPayment: 'Obrigado por nos escolher! A reserva e o seu pagamento foram gerados com sucesso. Em breve receberá um email de confirmação com a emissão do mesmo. Se você tiver alguma dúvida ou precisar de ajuda, entre em contato conosco.',
        receiveReservationTo: 'Você receberá sua reserva por e-mail para',
        spam: 'Não se esqueça de verificar sua pasta de Spam e Lixo Eletrônico.',
        bookingDetail: 'Acesse os detalhes da sua reserva',
        myBookings: 'Minhas reservas',
        addProducts: 'Adicione mais serviços para esses passageiros',
        newBooking: 'Quer fazer uma nova reserva?',
        reserve: 'Reserva',
        insufficientCreditMessage: 'Obrigado por nos escolher! A reserva não pôde ser emitida porque o crédito em conta corrente é insuficiente. Entre em contato conosco para verificar o status da sua conta corrente ou tente novamente o pagamento com cartão de crédito.',
        checkRealStatus: 'Antes de continuar a reserva, lembre-se de pedir ao seu consultor de vendas para verificar o real status da reserva junto ao fornecedor, para evitar possíveis gastos.',
        checkRealStatusConfirm: 'Entre em contato com seu consultor de vendas para verificar o status final do mesmo e auxiliá-lo na emissão.',
        reservationPendingPaymentFlight: 'A reserva foi gerada e está pendente de emissão. Só é garantido mediante pagamento. Lembre-se que a taxa pode variar até o momento da emissão. Caso não solicite a emissão no mesmo dia, a reserva será automaticamente cancelada.<br/>Horário de atendimento aos sábados, domingos e feriados, das 10h às 17h.',
        inAnalysis: 'Em análise',
        reservationInAnalysis: 'Sua reserva está sendo analisada',
        reservationInAnalysisMessage: 'Obrigado por nos escolher! O pagamento será analisado pelo departamento financeiro para aprovação final.'
      },
      hotels: {
        tariffWithCostsTitle: 'Importante! Taxa sobre as despesas.',
        tariffWithCostsSubtitle: 'Em caso de reserva, serão aplicadas taxas.',
        room: 'Quarto',
        adult: 'Adulto',
        minor: 'Menor',
        years: 'anos'
      },
      trains: {
        onlyFirstNameAndSurnameMessage: 'Inserir apenas primeiro nome e último sobrenome conforme informado no passaporte.',
        minorTicketsMessage: 'Infoas: Viajam gratuitamente sem direito a assento e sem bilhete de acordo com a idade: Comboios do Reino Unido 0-4 anos, Alemanha e Suíça 0-5 anos, resto dos Comboios 0-3 anos inclusive.',
        minorPassesMessage: 'Infoas: Viajam gratuitamente sem direito a assento e sem bilhete de acordo com as idades: Eurail Pass 0-3 anos e Swiss Pass 0-5 anos inclusive.',
        conditions: 'Condições de tarifa',
        showLess: 'Ver menos',
        showMore: 'Ver mais',
        emergencyEmailTooltip: 'Este contacto será utilizado pela companhia ferroviária para envio de alertas ou comunicações urgentes'
      }
    }
  }
};

export default pt;
