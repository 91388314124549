import React, {  useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Fab, Grid, IconButton, Table, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { getMinPriceActionFlights, searchThreeDaysActionFlights } from '../../../store/actions';
import { connect } from 'react-redux';
import { CalendarToday, Close, Info, Refresh } from '@mui/icons-material';
import Image from '../../common/Image';
import { LoadingButton } from '@mui/lab';
import { useClientData } from '../../../context/ClientContext';

const DATE_FORMAT_INTERNATIONAL = 'YYYY-MM-DD';
const STOPOVERS = [
  'results.flights.direct',
  'results.flights.oneScale',
  'results.flights.twoScales',
  'results.flights.threeScales'
];

function ThreeDaysTable({
  client,
  resultSearchThreeDays,
  errorThreeDays,
  resultSearchMinPrice,
  errorMinPrice,
  loadingResults,
  ...props
}) {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentProduct } = useClientData();
  const [openModal, setOpenModal] = useState(false);
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingFlight, setLoadingFlight] = useState({});
  const [lastSearch, setLastSearch] = useState();
  const [abortController, setAbortController] = useState();

  useEffect(() => {
    loadInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (loading && resultSearchThreeDays && Object.keys(resultSearchThreeDays).length > 0) {
      setResults(resultSearchThreeDays);
      setLoading(false);
      setOpenModal(true);

      const dataToSend = getDataToSend();
      const initialDates = getCentralDates(dataToSend.legs[0].flightDate, dataToSend.legs[1].flightDate, 3).flat();
      initialDates.forEach((elem) => getMinPrice(elem.departureDate, elem.arrivalDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultSearchThreeDays]);

  useEffect(() => {
    if (errorThreeDays) {
      setLoading(false);
      setResults(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorThreeDays]);

  useEffect(() => {
    if (resultSearchMinPrice) {
      const departureDate = resultSearchMinPrice.legs[0].flightDate;
      const arrivalDate = resultSearchMinPrice.legs[1].flightDate;
      const date = `${departureDate}-${arrivalDate}`;
      if (loadingFlight[date]) {
        const resultsAux = {...results};
        resultsAux.prices[departureDate][arrivalDate] = {
          value: resultSearchMinPrice.min_price,
          fare_id: resultSearchMinPrice.fare_id,
          data: resultSearchMinPrice.data || null,
          type: ['REAL_TIME']
        };
        setResults(resultsAux);
        setLoadingFlight(current => ({
          ...current,
          [date]: false
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultSearchMinPrice]);

  useEffect(() => {
    if (errorMinPrice) {
      const departureDate = errorMinPrice.legs[0].flightDate;
      const arrivalDate = errorMinPrice.legs[1].flightDate;
      const date = `${departureDate}-${arrivalDate}`;
      if (loadingFlight[date]) {
        setLoadingFlight(current => ({
          ...current,
          [date]: false
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMinPrice]);

  const getDataToSend = () => {
    const { tripType, origins, destinations, datesDeparture, datesReturn, adults, kids, babys, cabinClasses } = params;

    const originsAux = origins.split(',');
    const destinationsAux = destinations.split(',');
    const datesDepartureAux = datesDeparture.split(',');
    const datesReturnAux = datesReturn !== 'NA' ? datesReturn.split(',') : null;

    const legs = originsAux.map((origin, i) => ({
      departureAirportCity: origin,
      arrivalAirportCity: destinationsAux[i],
      flightDate: dayjs(datesDepartureAux[i], 'DDMMYY').format(DATE_FORMAT_INTERNATIONAL)
    }));

    if (tripType === 'roundtrip') {
      legs.push({
        departureAirportCity: destinationsAux[0],
        arrivalAirportCity: originsAux[0],
        flightDate: dayjs(datesReturnAux[0], 'DDMMYY').format(DATE_FORMAT_INTERNATIONAL)
      });
    }

    const passengers = [];

    if (parseInt(adults)) {
      passengers.push({
        type: "ADT",
        subType: "",
        count: adults
      });
    }
    if (parseInt(kids)) {
      passengers.push({
        type: "CHD",
        subType: "",
        count: kids
      });
    }
    if (parseInt(babys)) {
      passengers.push({
        type: "INF",
        subType: "",
        count: babys
      });
    }

    const dataToSend = {
      legs,
      passengers,
      airlines: "NA",
      excludedAirlines: "NA",
      cabinClasses,
      market: "NA"
    };

    return dataToSend;
  }

  const getCentralDates = (dateForColumns, dateForRows, X) => {
    const centralDateCol = dayjs(dateForColumns);
    const centralDateRow = dayjs(dateForRows);
    const dateMatrix = [];
    const range = Math.floor(X / 2);
    for (let i = -range; i <= range; i++) {
        const row = [];
        for (let j = -range; j <= range; j++) {
            const currentRowDate = centralDateRow.add(i, 'day');
            const currentColDate = centralDateCol.add(j, 'day');

            row.push({
              departureDate: currentColDate.format(DATE_FORMAT_INTERNATIONAL),
              arrivalDate: currentRowDate.format(DATE_FORMAT_INTERNATIONAL)
            })
        }

        dateMatrix.push(row);
    }

    return dateMatrix;
  }

  const loadInitialData = async () => {
    try {
      const search = new URLSearchParams(location.search);

      if (lastSearch !== location.pathname || search.size === 0) {
        setLastSearch(location.pathname);

        const { currency } = params;
        const token = currentProduct?.config?.['API-Token']?.[currency];
        const dataToSend = {
          ...getDataToSend(),
          results: 'CACHED'
        };

        const controller = new AbortController();

        if (search.size > 0 && search.get('searchId') && (!results || Object.keys(results).length === 0)) {
          setLoading(true);
          abortController && abortController.abort();
          setAbortController(controller);
          props.searchThreeDaysProducts(token, dataToSend, controller.signal);
        } else {
          if (location.state?.resultsThreeDays) {
            setResults(location.state?.resultsThreeDays);
          } else {
            setLoading(true);
            abortController && abortController.abort();
            setAbortController(controller);
            props.searchThreeDaysProducts(token, dataToSend, controller.signal);
          }
        }
      }
    } catch (error) {
      navigate('/login', { state: { expiredSession: true } });
    }
  }

  const isSelectedDate = (dayA, dayB) => {
    return dayjs(dayA).isSame(dayjs(dayB), 'date');
  }

  const isClickable = (departureDate, arrivalDate) => {
    return dayjs(departureDate).isAfter(dayjs(), 'date')
      && dayjs(arrivalDate).isAfter(dayjs(), 'date')
      && (!dayjs(departureDate).isSame(dayjs(results?.originalSearch?.departure), 'date')
        || !dayjs(arrivalDate).isSame(dayjs(results?.originalSearch?.arrival), 'date'))
      && dayjs(departureDate).isBefore(dayjs(arrivalDate), 'date');
  }

  const getClassNameCell = (departure, arrival) => {
    const departureSearch = results?.originalSearch?.departure;
    const arrivalSearch = results?.originalSearch?.arrival;
    let className = [];

    if (isClickable(departure, arrival)) {
      className.push('price-cell');
    }

    if (dayjs(departure).isSame(dayjs(departureSearch), 'date') && dayjs(arrival).isSame(dayjs(arrivalSearch), 'date')) {
      className.push('selected-price');
    }

    if (dayjs(departure).isSameOrBefore(dayjs(departureSearch), 'date')
      && dayjs(arrival).isBetween(dayjs(arrivalSearch).subtract(1, 'day'), dayjs(arrivalSearch), 'date', '[]')
    ) {
      className.push('border-bottom-price');
    }

    if (dayjs(arrival).isSameOrBefore(dayjs(arrivalSearch), 'date')
      && dayjs(departure).isBetween(dayjs(departureSearch).subtract(1, 'day'), dayjs(departureSearch), 'date', '[]')
    ) {
      className.push('border-right-price');
    }

    return className.join(' ');
  }

  const onClickPrice = (departureDate, arrivalDate) => {
    if (isClickable(departureDate, arrivalDate)) {
      const { tripType, origins, destinations, adults, kids, babys, cabinClasses, currency } = params;
      const departure = dayjs(departureDate);
      const arrival = dayjs(arrivalDate);
      const resultsThreeDays = JSON.parse(JSON.stringify(results));
      resultsThreeDays.originalSearch = {
        departure: departure.format('YYYY-MM-DD'),
        arrival: arrival.format('YYYY-MM-DD')
      };
      const url = `/vuelos/resultados/${tripType}/${origins}/${destinations}/${departure.format('DDMMYY')}/${arrival.format('DDMMYY')}/${adults}/${kids}/${babys}/${cabinClasses}/1/${currency}`;
      const price = results?.prices?.[departureDate]?.[arrivalDate]?.value
        ? formatNumber(parseInt(results.prices[departureDate][arrivalDate].value))
        : null;
      navigate(url, { state: { resultsThreeDays, price } });
      setOpenModal(false);
    }
  }

  const showPrice = (departureDate, arrivalDate) => {
    return isClickable(departureDate, arrivalDate)
      || (departureDate === results?.originalSearch?.departure
        && arrivalDate === results?.originalSearch?.arrival
      )
  }

  const formatNumber = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  const onCloseModal = () => {
    setOpenModal(false);
  }

  const getPriceContainer = (data) => {
    let className = 'price-container';

    if (data?.type?.includes('REAL_TIME')) {
      className += ' real-time-price';
    }

    return <Grid className={className}>
      {data?.value && <>{results?.currency} {formatNumber(parseInt(data?.value || 0))}</>}
    </Grid>
  }

  const getFlightsData = (flights) => {
    if (flights) {
      return <Grid className='flights-data-container'>
        {flights.map((flight, i) => (
          <div key={i} className='row flight-data'>
            <div className='airline-logo-container'>
              <Image img={`providersFlights/${flight.OperatingAirline[0]}.png`} alt='logo-provider' className='airline-logo' hideBrokenImage />
            </div>
            <Typography className='stopovers'>{t(STOPOVERS[flight.Stopovers])}</Typography>
          </div>
        ))}
      </Grid>
    }
  }

  const getMinPrice = (departureDate, returnDate) => {
    const { currency } = params;
    const token = currentProduct?.config?.['API-Token']?.[currency];

    if (token) {
      const dataToSend = getDataToSend();
      dataToSend.legs[0].flightDate = departureDate;
      dataToSend.legs[1].flightDate = returnDate;

      props.getMinPrice(token, dataToSend);
      setLoadingFlight(current => ({
        ...current,
        [`${departureDate}-${returnDate}`]: true
      }));
    }
  }

  const onClickRefresh = (e, departureDate, returnDate) => {
    e.stopPropagation();
    getMinPrice(departureDate, returnDate);
  }

  const getTooltipData = (data) => {
    if (data) {
      return data.map((leg, i) => (
        <Grid key={i} className='flight-data-tooltip'>
          <span className='date'>{dayjs(leg.Departure.Date).format('ddd DD MMM YY')}</span>
          <Grid className='row airports-container'>
            <Grid className='row airport'>{leg.Departure.AirportCode} {leg.Departure.Time}</Grid>
            <span className='stopovers'>{t(STOPOVERS[leg.Stopovers])}</span>
            <Grid className='row airport'>{leg.Arrival.AirportCode} {leg.Arrival.Time}</Grid>
          </Grid>
          <Grid className='row duration-container'>
            <Grid className='row'>
              {t('results.flights.duration')}&nbsp;
              <span className='duration'>{leg.OptionDurationHours}h {leg.OptionDurationMinutes}m</span>
            </Grid>
            <Image img={`providersFlights/${leg.OperatingAirline[0]}.png`} alt='logo-provider' className='airline-logo' hideBrokenImage />
          </Grid>
        </Grid>
      ))
    }
  }

  return (
    <Grid className='three-days-container'>
      <Grid container className='three-days-content'>
        <Grid item xs={6} className='title'>
          {t('results.flights.threeDaysPrices')}
        </Grid>

        <Grid item xs={6} className='left-side'>
          <LoadingButton
            variant='outlined'
            loading={loading}
            disabled={!results || Object.keys(results).length === 0}
            onClick={() => setOpenModal(true)}
          >
            <CalendarToday fontSize='small' />
            <span>{t('results.flights.checkPrices')}</span>
          </LoadingButton>
        </Grid>
      </Grid>

      <Dialog
        open={openModal && !loadingResults && !loading}
        onClose={onCloseModal}
        className='closable-modal three-days-modal'
        fullWidth
        maxWidth='xl'
      >
        <Fab size="small" onClick={onCloseModal} className='close-button'>
          <Close fontSize='small' />
        </Fab>

        <DialogTitle>
          <Typography className='title'>{t('results.flights.threeDaysPrices')}</Typography>
          <Typography className='message'>
            {t('results.flights.threeDaysMessage')}

            <Grid className='row legend-container'>
              <Grid className='row'>
                <div className='circle green' /> {t('results.flights.availableRate')}
              </Grid>

              <Grid className='row'>
                <div className='circle yellow' /> {t('results.flights.estimatedRate')}
              </Grid>
            </Grid>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Table size='small'>
            <TableRow>
              <TableCell className='first-cell'>
                <span>{t('results.flights.departure')}</span>
                <span>{t('results.flights.return')}</span>
              </TableCell>
              {results?.departureDates?.map((departureDate, i) => (
                <TableCell
                  key={i}
                  align='center'
                  className={isSelectedDate(departureDate, results?.originalSearch?.departure) ? 'selected-date' : ''}
                >
                  <span>{dayjs(departureDate).format('dddd')}</span>&nbsp;
                  <span className='date'>{dayjs(departureDate).format('DD MMM')}.</span>
                </TableCell>
              ))}
            </TableRow>
            {results?.returnDates?.map((returnDate, i) => (
              <TableRow key={i}>
                <TableCell
                  align='center'
                  className={isSelectedDate(returnDate, results?.originalSearch?.arrival) ? 'selected-date' : ''}
                >
                  <p>{dayjs(returnDate).format('dddd')}</p>
                  <p className='date'>{dayjs(returnDate).format('DD MMM')}.</p>
                </TableCell>
                {results?.departureDates?.map((departureDate, j) => (
                  <TableCell
                    key={j}
                    align='center'
                    className={getClassNameCell(departureDate, returnDate)}
                    onClick={() => onClickPrice(departureDate, returnDate)}
                  >
                    {loadingFlight[`${departureDate}-${returnDate}`] ? (
                      <Image img={`sites/${client.client.name}/preloadAereosSmall.gif`} className='loading-logo' hideBrokenImage />
                    ) : (
                      showPrice(departureDate, returnDate) && (
                        <>
                          {!results?.prices?.[departureDate]?.[returnDate]?.value ? (
                            <Tooltip title={t('results.flights.refreshFare')}>
                              <IconButton
                                className='refresh-icon-large'
                                onClick={(e) => onClickRefresh(e, departureDate, returnDate)}
                              >
                                <Refresh fontSize='large' />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <>
                              <Grid>
                                {getPriceContainer(results?.prices?.[departureDate]?.[returnDate])}
                                {getFlightsData(results?.prices?.[departureDate]?.[returnDate]?.data)}
                              </Grid>

                              {(!results?.prices?.[departureDate]?.[returnDate]?.type?.includes('CACHED') && results?.prices?.[departureDate]?.[returnDate]?.data) ? (
                                <Tooltip title={getTooltipData(results?.prices?.[departureDate]?.[returnDate]?.data)}>
                                  <Info fontSize='small' className='info-icon' />
                                </Tooltip>
                              ) : (
                                <Tooltip title={t('results.flights.refreshFare')}>
                                  <IconButton
                                    size='small'
                                    className='refresh-icon'
                                    onClick={(e) => onClickRefresh(e, departureDate, returnDate)}
                                  >
                                    <Refresh fontSize='small' />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </>
                      )
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </Table>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

const mapStateToProps = reducers => {
  return reducers.flightsReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    searchThreeDaysProducts: (token, dataForm, signalAbort = null) => dispatch(searchThreeDaysActionFlights(token, dataForm, signalAbort)),
    getMinPrice: (token, dataForm, signalAbort = null) => dispatch(getMinPriceActionFlights(token, dataForm, signalAbort))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ThreeDaysTable);
